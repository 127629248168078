import React from "react"

import GlitchText from 'react-glitch-effect/core/GlitchText'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { LinkIn } from '../components/Link'
import Layout from '../layouts/Website'
import Paper from '../components/Paper'
import Heading from '../components/Heading'


const useStyles = makeStyles((theme) => ({
}));

const IndexPage = () => {
  const classes = useStyles();
  return (
    <Layout>
        <Grid item xs={12}>
          <Paper>
            <Heading title='Page not found' />

            <Typography variant='body1' component='p'>
              <LinkIn to='/console' 
              >
              <GlitchText>
              console {'>'}
              </GlitchText>
              </LinkIn>
            </Typography>

          </Paper>
        </Grid>
    </Layout>
  )
}

export default IndexPage
